@font-face {
  font-family: 'TextaAltLight';
  src: url('../fonts-web/TextaAltLight.woff2') format('woff2'),
      url('../fonts-web/TextaAltLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


// 1. Global
// ---------

// COLORS
$black: #222222;
$white: #fefefe;
$apple-blue: #97cdcc;
$pink: #f3a7a1;
$green: #79cc88;
$purple: #6a3cb8;
$purple-light: #d8ccec;
$global-width: rem-calc(1200);
$global-font-size: 100%;
$global-lineheight: 1.5;
$orange-light: #feeadf;
$orange: #ee6b56;
$blue-light:#a6edff;
$blue: #206AE6;;

// BASE COLORS
$primary-color: $blue;
$secondary-color: $green;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: #ec5840;
$light-grey-extra: #f2f2f2; // first column
$grid-grey-line: #dddddd;
$medium-grey: #7e7e7e;
$dark-grey: #4f4f4f;

// BASICS
$body-background: $white;
$body-font-color: $black;

$body-font-family: IBM Plex Sans;
$body-font-family-bold: IBM Plex Sans, sans-serif; // google font
$special-font-family: IBM Plex Sans, sans-serif;  // google font

$body-antialiased: true;
$text-direction: ltr;
$global-margin: 1rem;
$global-padding: 1rem;
$global-margin: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-text-direction: ltr;

$rem-base: 16px;
$line-color: rgba($primary-color, 0.2);

// HEADER: NAVIGATION for /navigation-bar/navigation.scss
$header-picture-height: 600px;
$nav-breakpoint: 800px;
$nav-background-color: transparent;
$nav-height: 50px;
$nav-font-color: #FFF;
$nav-link-hover-color: darken($primary-color, 10%);

// CONTENT block
$content-default-padding-top-bottom: 30px;

// BLOCK
$block-default-padding: 15px;
$block-basic-padding: 35px;
$block-extra-padding: 70px;

// FOOTER
$footer-default-padding-top-bottom: 40px;
$footer-background-color: $medium-grey;
$footer-font-color: #FFF;
$footer-font-size: 12px;
$sitemap-font-link-color: #FFF;
$sitemap-font-link-color-hover: $medium-grey;


// TEXT SIZES
$big-title-size: 28px;
$medium-title-size: 22px;
$basic-title-size: 20px;
$text-size: 17px;
$small-size: 13px;
