@font-face {
  font-family: 'TextaAltLight';
  src: url("../fonts-web/TextaAltLight.woff2") format("woff2"), url("../fonts-web/TextaAltLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-size: 17px;
  line-height: 25px;
  overflow-x: hidden;
  font-family: IBM Plex Sans;
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: relative;
}

.content {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.header {
  height: 305px;
}

.background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.background .columns,
.background .column {
  height: 100%;
}

h1 {
  margin: 0px 0 15px 0;
  font-size: 28px;
  line-height: 30px;
}

h2, h3 {
  font-size: 20px;
  margin: 5px 0;
  color: #000;
  border: 0;
}

h3 {
  font-size: 17px;
  line-height: 19px;
}

a:hover h2 {
  color: #206AE6;
}

.content a {
  text-decoration: none;
}

.content p {
  margin-top: 8px;
  margin-bottom: 5px;
}

h2 {
  font-weight: 600;
}

.dark-grey {
  color: #4f4f4f;
}

h3 {
  font-weight: 200;
}

h4 {
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0px 0 0 0;
  color: #7e7e7e;
  font-size: 13px;
}

h5 {
  color: #363636;
  font-size: 17px;
  padding: 0.5em 0.75em;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 10px;
}

.line-before {
  position: relative;
  padding-left: 60px;
}

.line-before:before {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  height: 2px;
  width: 50px;
  background: #000;
  left: 25px;
  z-index: 1;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

img {
  width: 100%;
}

img.greyscale {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

a:hover img.greyscale {
  -webkit-filter: none;
  /* Safari 6.0 - 9.0 */
  filter: none;
}

.small {
  font-size: 13px;
}

a.black-link {
  color: #000;
  border-bottom: 1px solid transparent;
}

a.black-link:hover {
  border-bottom: 1px solid #000;
}

a.link-with-border {
  color: #000;
  border-bottom: 1px solid #dddddd;
}

a.link-with-border:hover {
  border-bottom: 1px solid #000;
}

.content p a {
  border-bottom: 1px solid #dddddd;
  color: #000;
  margin-top: 8px;
  margin-bottom: 5px;
}

.content p a:hover {
  border-bottom: 1px solid #000;
}

blockquote {
  color: #000;
  font-size: 2.2rem;
  letter-spacing: -.05rem;
  padding: 20px;
  font-weight: normal;
  font-size: 20px;
  width: 70%;
  background-color: #F2F2F2;
  margin: 20px auto;
}

blockquote .author {
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
  color: #7e7e7e;
  font-size: 13px;
}

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.iframe-container iframe,
.iframe-container object,
.iframe-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figure {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

figure iframe,
figure object,
figure embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%  !important;
}

.image-container {
  position: relative;
  z-index: 15;
  /* on top of content footer) */
}

.image-container.leftCorner {
  width: 30%;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table td,
table th {
  text-align: left;
  vertical-align: top;
}

table th {
  color: #363636;
}

.content table {
  width: 100%;
}

.content table td,
.content table th {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.content table td.extra-top-border {
  border-top: 1px solid #dbdbdb;
}

.content table tr.hover-grey:hover td {
  background: #efefef;
}

.content table tr.click-link-effect td {
  cursor: pointer;
}

.content table th {
  color: #363636;
  text-align: left;
}

.content table thead td,
.content table thead th {
  border-width: 0 0 1px;
  color: #363636;
}

.content table tfoot td,
.content table tfoot th {
  border-width: 1px 0 0;
  color: #363636;
}

.mobile .navigation {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

.mobile .navigation li {
  margin: 0;
  text-align: center;
  padding: 0;
}

.mobile label {
  /* clicked */
}

.mobile label .menu {
  position: fixed;
  right: -60px;
  top: 25px;
  z-index: 100;
  width: 120px;
  height: 120px;
  background: #FFF;
  border-radius: 50% 50% 50% 50%;
  transition: .5s ease-in-out;
  box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
  cursor: pointer;
}

.mobile label .hamburger {
  position: absolute;
  top: 60px;
  left: 25px;
  width: 20px;
  height: 1px;
  background: #6b6b6b;
  display: block;
  transform-origin: center;
  transition: .5s ease-in-out;
}

.mobile label .hamburger:after, .mobile label .hamburger:before {
  transition: .5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #6b6b6b;
}

.mobile label .hamburger:before {
  top: -10px;
}

.mobile label .hamburger:after {
  bottom: -10px;
}

.mobile label input {
  display: none;
}

.mobile label ul {
  z-index: 200;
  position: relative;
  top: -20%;
  margin-top: 0%;
  /* transform: translate(-50%,-50%); */
  opacity: 0.0;
  display: none;
  transition: 0.3s 0s ease-in-out;
}

.mobile label a {
  margin-bottom: 1em;
  display: block;
  color: #000;
  text-decoration: none;
}

.mobile label a:hover {
  color: #206AE6;
}

.mobile label input:checked + .menu {
  box-shadow: 0 0 0 100vw #FFF, 0 0 0 100vh #FFF;
  border-radius: 0;
}

.mobile label input:checked + .menu .hamburger {
  transform: rotate(45deg);
}

.mobile label input:checked + .menu .hamburger:after {
  transform: rotate(90deg);
  bottom: 0;
}

.mobile label input:checked + .menu .hamburger:before {
  transform: rotate(90deg);
  top: 0;
}

.mobile label input:checked + .menu + ul {
  opacity: 1;
  display: block;
  margin-top: 30%;
}

.desktop ul.navigation {
  list-style: none;
  text-decoration: none;
  margin: 15px 0 0 0;
  padding: 0;
}

.desktop ul.navigation li {
  margin: 0;
  padding: 0;
}

.desktop ul.navigation li a {
  color: #000;
  text-decoration: none;
}

.desktop ul.navigation li.active:hover a,
.desktop ul.navigation li a:hover {
  color: #206AE6;
}

.desktop .brand-logo-container {
  text-align: center;
}

.desktop .desktop-logo {
  margin: 100px auto 0;
  width: 70%;
  opacity: 0.8;
}

.desktop .section-title-container {
  margin: 150px auto 0;
}

.desktop .section-title-container .section-title {
  transform: rotate(-90deg);
  text-align: center;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 400;
}

.desktop .section-title-container.out-of-shape {
  position: absolute;
  top: 100px;
  opacity: 0.2;
  margin-top: 350px;
  width: 90%;
  padding: .75rem;
  transition: .5s ease-in-out;
}

.desktop .logo-section-container {
  transition: opacity 1.5s;
}

.desktop .logo-section-container:hover .desktop-logo {
  opacity: 1;
}

.desktop .logo-section-container:hover .section-title-container.out-of-shape {
  opacity: 0.6;
}

.brand-logo-svg {
  height: 0;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  background: url(../images/common/logo_onandfor.svg);
  width: 89px;
  padding-top: 44px;
  background-size: 89px 44px;
  margin-top: 10px;
}

.icon {
  height: 0;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  font-size: 0;
  width: 18px;
  padding-top: 18px;
  background-size: 18px 18px;
  position: relative;
  top: 2px;
}

.link-icon {
  background: url(../images/common/link-icon.svg);
  width: 16px;
  padding-top: 16px;
  background-size: 16px 16px;
  opacity: 0.6;
}

a:hover .link-icon {
  opacity: 1;
}

.columns {
  display: flex;
}

.columns.extra-top-margin, .extra-top-margin {
  margin-top: 30px;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: .75rem;
  background: transparent;
  /* sizes */
}

.column.grey-bg {
  background: #f2f2f2;
}

.column.border-right {
  border-right: 1px solid #dddddd;
}

.column.first {
  position: relative;
}

.column.is-three-fifths {
  flex: none;
  width: 60%;
}

.column.is-one-fifths {
  flex: none;
  width: 20%;
}

.column.is-four-fifths {
  flex: none;
  width: 80%;
}

.column .columns {
  margin: -0.75rem;
}

.row {
  clear: both;
  padding: 4px 0;
  border-bottom: 1px solid #dddddd;
}

.outer-90-degrees {
  width: 50px;
  position: relative;
  display: block;
  margin: 0 15px;
  float: left;
  height: 160px;
}

.inner-90-degrees {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.type {
  font-size: 13px;
  color: #7e7e7e;
  letter-spacing: 5px;
  text-align: center;
  text-transform: uppercase;
}

.grey {
  color: #7e7e7e;
}

.grey table thead th, .grey a {
  color: #7e7e7e !important;
}

.cat-links {
  margin-top: 15px;
}

.cat-links a {
  color: #222222;
}

.cat-links a:hover {
  color: #206AE6;
}

.cat-links .active a {
  font-weight: 600;
}

a .shorten-link {
  color: #222222;
  display: block;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-footer {
  font-size: 11px;
  position: absolute;
  z-index: 12;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  text-align: center;
  width: 20%;
}

.content-footer a {
  color: #7e7e7e;
  text-decoration: none;
}

.content-footer .footer-links a {
  padding: 0 6px;
}

.content-footer a:last-child {
  border-right: 0;
}

.content-footer a:hover {
  color: #222222;
}

.content-footer .footer-links {
  position: relative;
  z-index: 12;
  width: 100%;
}

.newsletter-layover {
  display: block;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.newsletter-layover .content {
  margin-top: 20%;
  text-align: center;
}

.logo-flag-container {
  margin-top: 10px;
  text-align: center;
}

.logo-flag-container .logo-flag-eu {
  float: left;
  height: 0;
  display: inline-block;
  overflow: hidden;
  font-size: 0;
  background: url(../images/common/logo_eu_flag.svg);
  width: 62px;
  padding-top: 34px;
  background-size: 62px 34px;
}

.logo-flag-container .text {
  font-size: 8px;
  line-height: 11px;
  color: #7e7e7e;
  text-align: left;
  vertical-align: middle;
  height: 34px;
  padding-top: 2px;
}

.news-flash {
  background-color: #f2f2f2;
  padding: 10px 10px;
  margin: 20px 0;
}

button[data-balloon] {
  overflow: visible;
}

[data-balloon] {
  position: relative;
  cursor: pointer;
}

[data-balloon]:after {
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.18s ease-out 0.18s;
  -moz-transition: all 0.18s ease-out 0.18s;
  -ms-transition: all 0.18s ease-out 0.18s;
  -o-transition: all 0.18s ease-out 0.18s;
  transition: all 0.18s ease-out 0.18s;
  font-family: sans-serif !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-shadow: none !important;
  font-size: 12px !important;
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #fff;
  content: attr(data-balloon);
  padding: .5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
}

[data-balloon]:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.18s ease-out 0.18s;
  -moz-transition: all 0.18s ease-out 0.18s;
  -ms-transition: all 0.18s ease-out 0.18s;
  -o-transition: all 0.18s ease-out 0.18s;
  transition: all 0.18s ease-out 0.18s;
  content: '';
  position: absolute;
  z-index: 10;
}

[data-balloon]:hover:before, [data-balloon]:hover:after, [data-balloon][data-balloon-visible]:before, [data-balloon][data-balloon-visible]:after {
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  pointer-events: auto;
}

[data-balloon].font-awesome:after {
  font-family: FontAwesome;
}

[data-balloon][data-balloon-break]:after {
  white-space: pre;
}

[data-balloon][data-balloon-blunt]:before, [data-balloon][data-balloon-blunt]:after {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

[data-balloon][data-balloon-pos="up"]:after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 11px;
  -webkit-transform: translate(-50%, 10px);
  -moz-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up"]:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  -webkit-transform: translate(-50%, 10px);
  -moz-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up"]:hover:after, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos="up"]:hover:before, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos="up-left"]:after {
  bottom: 100%;
  left: 0;
  margin-bottom: 11px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up-left"]:before {
  bottom: 100%;
  left: 5px;
  margin-bottom: 5px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up-left"]:hover:after, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos="up-left"]:hover:before, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos="up-right"]:after {
  bottom: 100%;
  right: 0;
  margin-bottom: 11px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up-right"]:before {
  bottom: 100%;
  right: 5px;
  margin-bottom: 5px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}

[data-balloon][data-balloon-pos="up-right"]:hover:after, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos="up-right"]:hover:before, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos='down']:after {
  left: 50%;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}

[data-balloon][data-balloon-pos='down']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 50%;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}

[data-balloon][data-balloon-pos='down']:hover:after, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos='down']:hover:before, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-balloon][data-balloon-pos='down-left']:after {
  left: 0;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
}

[data-balloon][data-balloon-pos='down-left']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 5px;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
}

[data-balloon][data-balloon-pos='down-left']:hover:after, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos='down-left']:hover:before, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos='down-right']:after {
  right: 0;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
}

[data-balloon][data-balloon-pos='down-right']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  right: 5px;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px);
}

[data-balloon][data-balloon-pos='down-right']:hover:after, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos='down-right']:hover:before, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-balloon][data-balloon-pos='left']:after {
  margin-right: 11px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  transform: translate(10px, -50%);
}

[data-balloon][data-balloon-pos='left']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  margin-right: 5px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  transform: translate(10px, -50%);
}

[data-balloon][data-balloon-pos='left']:hover:after, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-pos='left']:hover:before, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-pos='right']:after {
  left: 100%;
  margin-left: 11px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  -moz-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
}

[data-balloon][data-balloon-pos='right']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  left: 100%;
  margin-left: 5px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  -moz-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%);
}

[data-balloon][data-balloon-pos='right']:hover:after, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-pos='right']:hover:before, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[data-balloon][data-balloon-length='small']:after {
  white-space: normal;
  width: 80px;
}

[data-balloon][data-balloon-length='medium']:after {
  white-space: normal;
  width: 150px;
}

[data-balloon][data-balloon-length='large']:after {
  white-space: normal;
  width: 260px;
}

[data-balloon][data-balloon-length='xlarge']:after {
  white-space: normal;
  width: 380px;
}

@media screen and (max-width: 768px) {
  [data-balloon][data-balloon-length='xlarge']:after {
    white-space: normal;
    width: 90vw;
  }
}

[data-balloon][data-balloon-length='fit']:after {
  white-space: normal;
  width: 100%;
}

#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  font-size: 17px;
  line-height: 22px;
}

#mc_embed_signup h2 {
  margin-bottom: 25px;
}

#mc_embed_signup .button {
  clear: both;
  background-color: #7e7e7e;
  border: 0;
  transition: all 0.23s ease-in-out 0s;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  line-height: 42px;
  margin: 15px 5px 10px 0;
  padding: 5px 30px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}

#mc_embed_signup .button:hover {
  background-color: #79cc88;
}

#mc_embed_signup .indicates-required {
  display: none;
}

#mc_embed_signup .asterisk {
  color: #dddddd;
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
}

#mc_embed_signup .mc-field-group {
  clear: left;
  position: relative;
  width: 96%;
  padding: 10px 0;
  min-height: 40px;
}

#mc_embed_signup label {
  display: block;
}

#mc_embed_signup input {
  border: 1px solid #7e7e7e;
  border-radius: 0;
  width: 100%;
  font-size: 15px;
  padding: 10px;
}

#mc_embed_signup input.mce_inline_error {
  border-color: red !important;
}

#mc_embed_signup div.mce_inline_error {
  background-color: #000 !important;
}

body.texts {
  background-color: #fdf6cf;
}

body.texts .grey-bg {
  background-color: #fdf6cf;
}

body.texts .paragraph h2 {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

body.texts .paragraph .image-container {
  width: 130%;
  margin-left: -15%;
  margin-top: 25px;
  margin-bottom: 25px;
}

body.texts .paragraph .paragraph-footer {
  font-size: 13px;
  font-weight: 200;
}

body.texts .desktop .section-title-container.out-of-shape {
  display: none;
}

body.texts-overview .content {
  z-index: 900;
}

body.texts-overview .blocks-texts-overview {
  margin-bottom: 100px;
}

body.texts-detail .header {
  position: relative;
  z-index: 999;
  color: #fff;
}

body.texts-detail .header .desktop ul.navigation li a {
  color: #ccc;
}

body.texts-detail .full-width-image .image-container {
  width: 100%;
}

body.texts-detail .paragraph {
  margin-top: 25px;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

body.texts-detail figure {
  margin: 25px 0;
}

body.texts-detail .full-width-image {
  position: relative;
  top: -305px;
  z-index: 1;
}

body.texts-detail h1 {
  font-size: 60px;
  line-height: 60px;
  width: 140%;
  left: -20%;
  position: relative;
  top: -150px;
  text-align: center;
}

body.texts-detail .author {
  font-size: 18px;
  font-weight: 800;
  position: relative;
  top: -180px;
  text-align: center;
}

body.texts-detail .intro {
  font-size: 18px;
  line-height: 28px;
  font-weight: 800;
  position: relative;
  top: -80px;
  text-align: left;
}

body.texts-detail.navBlack1 .header,
body.texts-detail.navBlack1 .mobile label a,
body.texts-detail.navBlack1 .navigation li a {
  color: #000 !important;
}

body.texts {
  background-color: #fdf6cf;
}

body.texts .grey-bg {
  background-color: #fdf6cf;
}

body.texts a {
  color: #000;
}

body.texts .article {
  position: relative;
  z-index: 200;
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 50px;
  background-color: #fdf6cf;
  border: 1px solid #ccc;
}

body.texts .article:hover {
  background-color: #fdf4c5;
}

body.texts .image {
  width: 30%;
  padding-right: 25px;
  float: left;
}

body.texts .author {
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  body {
    background: #fff;
  }
  .header {
    height: 255px;
  }
  .desktop {
    display: none;
  }
  .content {
    position: relative;
  }
  .mobile {
    display: block;
  }
  .mobile-logo {
    margin: 20px 0 0 5px;
    width: 180px;
    opacity: 0.8;
  }
  .content-footer {
    font-size: 12px;
    position: relative;
    padding: .75rem;
    width: auto;
    width: 100%;
    margin: 50px 0 20px 0;
    z-index: 100;
  }
  .content-footer .row {
    border: 0;
  }
  .content-footer .column {
    border-top: 1px solid #7e7e7e;
  }
  .content-footer .logo-flag-container {
    text-align: center;
    margin: 30px 25%;
    width: 50%;
  }
  /* columns */
  .columns {
    display: block;
  }
  .hide-on-mobile {
    display: none;
  }
  .column.is-two-fifths,
  .column.is-four-fifths,
  .column.is-three-fifths {
    width: 100%;
  }
  .columns.columns-overview-mobile {
    display: flex;
  }
  .columns.columns-overview-mobile .column {
    width: 50%;
  }
  ul.navigation li {
    list-style: none;
    font-size: 30px;
  }
  body.texts .paragraph {
    padding-left: 0;
    padding-right: 0;
  }
  body.texts .image {
    width: 100%;
    padding-right: 0px;
    float: none;
  }
  body.texts .author {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  body.texts .article {
    margin-left: 2%;
    margin-right: 2%;
    padding: 10px;
  }
  body.texts h1 {
    font-size: 40px;
    line-height: 40px;
    width: 100%;
    left: 0%;
  }
  body.texts-overview .content {
    z-index: 10;
  }
}
